import {
  Entity,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API
} from '@backstage/catalog-model'
import { EntityHasApisCard } from '@backstage/plugin-api-docs'
import { EntityHasComponentsCard, EntityHasResourcesCard, EntityLayout } from '@backstage/plugin-catalog'
import { Direction, EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph'
import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react'
import { EntityTechdocsContent } from '@backstage/plugin-techdocs'
import { TechDocsAddons } from '@backstage/plugin-techdocs-react'
import { Grid } from '@material-ui/core'
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid'
import React from 'react'
import { AboutCardWithChildren } from '../../custom'
import { TelemetryCategorySystemOverviewCard } from '../../custom/telemetry/cards/TelemetryCategorySystemOverviewCard'
import { TelemetryCriticalitySystemByComponentChart } from '../../custom/telemetry/charts/TelemetryCriticalitySystemByComponentChart'
import { hasTelemetryData } from '../../util/helpers'
import EntityWarningContent from '../content/EntityWarningContent'

const SystemPage: React.FC = () => {
  const { entity } = useEntity()
  const { entities: relatedEntities } = useRelatedEntities(entity, { type: 'hasPart' })
  const relatedEntitiesWithTelemetryData = relatedEntities
    ? relatedEntities.filter((relatedEntity: Entity) => hasTelemetryData(relatedEntity))
    : []

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          {EntityWarningContent}
          <Grid item md={6}>
            <AboutCardWithChildren variant="gridItem" />
          </Grid>
          {relatedEntities && relatedEntitiesWithTelemetryData.length > 0 && (
            <Grid item md={6}>
              <Grid container>
                <Grid item md={6}>
                  <TelemetryCategorySystemOverviewCard relatedEntities={relatedEntitiesWithTelemetryData} />
                </Grid>
                <Grid item md={6}>
                  <TelemetryCriticalitySystemByComponentChart
                    entities={relatedEntitiesWithTelemetryData}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={400} />
          </Grid>
          <Grid item md={6}>
            <EntityHasComponentsCard variant="gridItem" />
          </Grid>
          <Grid item md={6}>
            <EntityHasApisCard variant="gridItem" />
          </Grid>
          <Grid item md={6}>
            <EntityHasResourcesCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      <EntityLayout.Route path="/diagram" title="Diagram">
        <EntityCatalogGraphCard
          variant="gridItem"
          direction={Direction.TOP_BOTTOM}
          title="System Diagram"
          height={700}
          relations={[
            RELATION_PART_OF,
            RELATION_HAS_PART,
            RELATION_API_CONSUMED_BY,
            RELATION_API_PROVIDED_BY,
            RELATION_CONSUMES_API,
            RELATION_PROVIDES_API,
            RELATION_DEPENDENCY_OF,
            RELATION_DEPENDS_ON
          ]}
          unidirectional={false}
        />
      </EntityLayout.Route>
      <EntityLayout.Route path="/docs" title="Docs">
        <EntityTechdocsContent>
          <TechDocsAddons>
            <Mermaid config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }} />
          </TechDocsAddons>
        </EntityTechdocsContent>
      </EntityLayout.Route>
    </EntityLayout>
  )
}

export default SystemPage

import { Result, Results } from '@linktr.ee/telemetry-api-client'
import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core'
import { TelemetryComponents } from 'backstage-plugin-telemetry'
import React, { useState } from 'react'
import { toPercent } from '../../../util/helpers'
import { TelemetryCategoryPercentAreaChart } from '../charts/TelemetryCategoryPercentAreaChart'

export type TelemetryCategoryResultsCardProps = {
  id: string
  /**
   * The name of the category, used in the card header
   */
  title: string
  /**
   * A blurb describing the category that will be displayed alongside the visualisation
   */
  blurb: string
  results: Results
  isActive?: boolean
  colour?: string
  onMouseOver?: () => void
}

const TelemetryCategoryResultsBlurb: React.FC<{ blurb: string }> = ({ blurb }) => {
  return (
    <Typography style={{ padding: '15px' }} variant="body2">
      <span dangerouslySetInnerHTML={{ __html: blurb }} />
    </Typography>
  )
}

export type TelemetryCategoryResult = {
  updatedAt?: number
  results: Result[]
  value: number
}

export const TelemetryCategoryResultsCard: React.FC<TelemetryCategoryResultsCardProps> = ({
  id,
  title,
  blurb,
  colour,
  results: { history: categorisedHistory, updatedAt: lastUpdated, results: categorisedResults },
  isActive,
  onMouseOver
}) => {
  const data: TelemetryCategoryResult[] = [
    ...(categorisedHistory ?? [])
      .sort((a, b) => (a.updatedAt ?? 0) - (b.updatedAt ?? 0))
      .map(({ updatedAt, results }) => ({
        updatedAt,
        value: toPercent(results.filter(({ success }) => success).length, results.length),
        results
      })),
    {
      updatedAt: lastUpdated,
      value: toPercent((categorisedResults ?? []).filter(({ success }) => success).length, categorisedResults.length),
      results: categorisedResults
    }
  ]

  const [activeResults, setActiveResults] = useState(data[data.length - 1].results)
  const [isLatestResult, setIsLatestResult] = useState(true)

  return (
    <Grid item md={6} xs={12}>
      <Card>
        <CardHeader title={title} />
        <TelemetryCategoryResultsBlurb blurb={blurb} />
        <CardContent>
          <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="flex-start">
            <Grid container direction="row">
              <Grid item md={12}>
                <TelemetryCategoryPercentAreaChart
                  chartStyle={{ containerHeight: 300, horizontalAlignment: '50%', innerRadius: '70%' }}
                  data={data}
                  colour={colour}
                  onMouseMove={nextState => {
                    if (nextState.activeTooltipIndex !== undefined) {
                      setActiveResults(data[nextState.activeTooltipIndex].results)
                      setIsLatestResult(data.length - 1 === nextState.activeTooltipIndex)
                      onMouseOver?.()
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <TelemetryComponents.ResultsTableAccordion
        id={id}
        isLatestResult={isLatestResult}
        expanded={isActive ?? false}
        results={activeResults}
      />
    </Grid>
  )
}

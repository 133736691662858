import { Entity } from '@backstage/catalog-model'
import { CardContent, CardHeader, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { ComponentsByCategoryRadarChart } from './ComponentsByCategoryRadarChart'

export type ComponentsByCategoryCardContentProps = {
  entities: Entity[]
  chartStyle?: {
    containerHeight?: number
  }
  title?: string
  description?: string
  showTitle?: boolean
  showDescription?: boolean
  showLegend?: boolean
  showTooltip?: boolean
}

export const ComponentsByCategoryCardContent: React.FC<ComponentsByCategoryCardContentProps> = ({
  entities,
  chartStyle,
  title = 'Telemetry Overview',
  description = 'An overview of the Telemetry results for the Components that make up this System.',
  showTitle = true,
  showDescription = true,
  showLegend = true,
  showTooltip = true
}) => {
  return (
    <>
      {showTitle && <CardHeader title={title} />}
      <CardContent>
        <Grid container>
          {showDescription && <Grid item md={12}>
            <Typography variant="body2">
              {description}
            </Typography>
          </Grid>}
          <Grid item md={12}>
            <ComponentsByCategoryRadarChart
              entities={entities}
              chartStyle={chartStyle}
              legend={showLegend}
              tooltip={showTooltip}
            />
          </Grid>
        </Grid>
      </CardContent>
    </>
  )
}

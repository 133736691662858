import { Entity } from '@backstage/catalog-model'
import React from 'react'
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps
} from 'recharts'
import { analyseResults, CategorisedResults, categoriseResultsByEntityName, resultsByEntityName, toColour, toHistorgram } from '../../util/helpers'
import { useCustomStyles } from '../../util'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'

function CustomTooltip({ active, payload }: TooltipProps<ValueType, NameType>) {
  const styles = useCustomStyles()

  if (active && payload && payload.length) {
    const { category } = payload[0].payload
    const histogram = Object.entries(toHistorgram(payload.map(({ value }) => ({ percent: value as number })))) as unknown as [number, number][]

    return (
      <div className={styles.tooltip}>
        <b>{category}</b>
        <br />
        {
          payload.length <= 5
            ? payload.map((data, index) => {
              return (
                <div style={{ color: toColour(index) }} key={`telemetry-components-by-category-radar-tooltip-${index}`}>
                  <b>{data.name}</b>: {`${data.value}%`}
                </div>
              )
            })
            : <div style={{ padding: '5px' }} >
              {histogram.map(([bucket, count], index) => {
                const nextThreshold = histogram[index+1]?.[0]
                return (
                  <div key={`telemetry-components-by-category-radar-tooltip-${bucket}`}>
                    <b style={{ float: 'left' }}>{bucket}{nextThreshold ? ` - ${nextThreshold - 1}` : ''}%</b>
                    <span style={{ float: 'right' }}>{count} {count === 1 ? 'Service' : 'Services'}</span>
                    <div style={{ clear: 'both' }} />
                  </div>
                )
              })}
            </div>
        }
      </div>
    )
  }

  return null
}

/**
 * @returns '[{ category: 'best-practice', 'fullMark': 100, 'entityA': 89, 'entityB': 34 }]
 */
function formatData(categorisedResultsByEntity: Record<string, CategorisedResults>): ({ category: string; fullMark: string } & Record<string, string>)[] {
  const formattedData: ({ category: string; fullMark: string } & Record<string, string>)[] = []

  Object.entries(categorisedResultsByEntity).forEach(([entityName, categorisedResults]) => 
    Object.entries(categorisedResults).forEach(([category, { results }]) => {
      const analysis = analyseResults(results)
      const resultIndex = formattedData.findIndex((data) => data.category === category)
      if (resultIndex === -1) {
        formattedData.push({
          category,
          fullMark: '100',
          [entityName.toLowerCase()]: String(analysis.percent)
        })
      } else {
        formattedData[resultIndex][entityName.toLowerCase()] = String(analysis.percent)
      }
    })
  )

  return formattedData
}

export type ComponentsByCategoryRadarChartProps = {
  entities: Entity[]
  chartStyle?: {
    containerHeight?: number
  }
  tooltip?: boolean
  legend?: boolean
}

export const ComponentsByCategoryRadarChart: React.FC<ComponentsByCategoryRadarChartProps> = ({
  entities,
  chartStyle,
  tooltip = true,
  legend = true
}) => {
  const entityResults = resultsByEntityName(entities)
  const categorisedResultsByEntity = categoriseResultsByEntityName(entityResults)

  return (
    <ResponsiveContainer width="100%" height={chartStyle?.containerHeight ?? 350}>
      <RadarChart
        cx="50%"
        cy="50%"
        outerRadius="80%"
        data={formatData(categorisedResultsByEntity)}
      >
        <PolarGrid />
        <PolarAngleAxis dataKey="category" />
        <PolarRadiusAxis domain={[0, 100]} />
        {Object.keys(categorisedResultsByEntity).map((entityName: string, index: number) => (
          <Radar
            key={`telemetry-components-by-category-radar-${index}`}
            dataKey={entityName.toLowerCase()}
            name={entityName}
            stroke={toColour(index)}
            fill={toColour(index)}
            fillOpacity={0.1}
            legendType="rect"
          />
        ))}
        {tooltip && <Tooltip content={<CustomTooltip />} />}
        {legend && <Legend />}
      </RadarChart>
    </ResponsiveContainer>
  )
}

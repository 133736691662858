import { BackstageOverrides } from '@backstage/core-components'
import { BackstageOverrides as CatalogReactOverrides } from '@backstage/plugin-catalog-react'
import { BackstageTheme, createTheme, lightTheme, genPageTheme, shapes } from '@backstage/theme'
import componentLibraryTailwindConfig from '@linktr.ee/component-library/tailwind.config.js'
import type { AutocompleteClassKey } from '@material-ui/lab/Autocomplete'
import type { AlertClassKey } from '@material-ui/lab/Alert'

// Labs types not included in overrides; https://github.com/mui/material-ui/issues/19427
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiAutocomplete: AutocompleteClassKey;
  }
}

const randomColors = [
  // 'sky',
  // 'dahlia',
  // 'orchid',
  // 'currant',
  // 'apple',
  // 'rose',
  // 'root',
  // 'poppy',
  'shade',
  // 'moss'
]

const randomColor = () =>
  componentLibraryTailwindConfig.theme.colors[randomColors[Math.floor(Math.random() * randomColors.length)]]

const linktreeBase = createTheme({
  palette: {
    ...lightTheme.palette,
    common: {
      black: componentLibraryTailwindConfig.theme.colors.black
    },
    primary: {
      main: componentLibraryTailwindConfig.theme.colors.primary
    },
    secondary: {
      main: componentLibraryTailwindConfig.theme.colors['primary-alt']
    },
    error: {
      main: componentLibraryTailwindConfig.theme.colors.danger
    },
    warning: {
      main: componentLibraryTailwindConfig.theme.colors.warning
    },
    info: {
      main: componentLibraryTailwindConfig.theme.colors.info
    },
    success: {
      main: componentLibraryTailwindConfig.theme.colors.success
    },
    background: {
      default: componentLibraryTailwindConfig.theme.colors.marble,
      paper: '#ffffff'
    },
    banner: {
      info: componentLibraryTailwindConfig.theme.colors.info,
      error: componentLibraryTailwindConfig.theme.colors.danger,
      text: componentLibraryTailwindConfig.theme.colors.marble,
      link: componentLibraryTailwindConfig.theme.colors.primary
    },
    errorBackground: '#8c4351',
    warningBackground: '#8f5e15',
    infoBackground: '#343b58',
    navigation: {
      background: componentLibraryTailwindConfig.theme.colors.charcoal,
      indicator: componentLibraryTailwindConfig.theme.colors.marble,
      color: componentLibraryTailwindConfig.theme.colors.marble,
      selectedColor: '#ffffff'
    }
  },
  defaultPageTheme: 'home',
  fontFamily:
    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({
      colors: [randomColor()],
      shape: shapes.round
    }),
    documentation: genPageTheme({
      colors: [randomColor()],
      shape: shapes.round
    }),
    tool: genPageTheme({ colors: [randomColor()], shape: shapes.round }),
    service: genPageTheme({
      colors: [randomColor()],
      shape: shapes.round
    }),
    website: genPageTheme({
      colors: [randomColor()],
      shape: shapes.round
    }),
    library: genPageTheme({
      colors: [randomColor()],
      shape: shapes.round
    }),
    other: genPageTheme({ colors: [randomColor()], shape: shapes.round }),
    app: genPageTheme({ colors: [randomColor()], shape: shapes.round }),
    apis: genPageTheme({ colors: [randomColor()], shape: shapes.round })
  }
})

const createCustomThemeOverrides = (theme: BackstageTheme): BackstageOverrides & CatalogReactOverrides => {
  return {
    BackstageHeader: {
      header: {
        backgroundColor: `${theme.page.colors[0]}`
      }
    },
    BackstageItemCardHeader: {
      root: {
        backgroundColor: `${randomColor()}`,
      }
    },
    BackstageHeaderTabs: {
      defaultTab: {
        fontSize: 'inherit',
        textTransform: 'none'
      }
    },
    BackstageOpenedDropdown: {
      icon: {
        '& path': {
          fill: '#FFFFFF'
        }
      }
    },
    BackstageTable: {
      root: {
        '&> :first-child': {
          borderBottom: '1px solid #D5D5D5',
          boxShadow: 'none'
        },
        '& th': {
          textTransform: 'none !important'
        }
      }
    },
    CatalogReactUserListPicker: {
      title: {
        textTransform: 'none'
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid #D5D5D5',
      },
      sizeSmall: {
        padding: '10px 12px'
      }
    },
    MuiAlert: {
      root: {
        borderRadius: 0
      },
      standardError: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.error.dark,
        '& $icon': {
          color: '#FFFFFF'
        }
      },
      standardInfo: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.primary.dark,
        '& $icon': {
          color: '#FFFFFF'
        }
      },
      standardSuccess: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.success.dark,
        '& $icon': {
          color: '#FFFFFF'
        }
      },
      standardWarning: {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.secondary.light,
        '& $icon': {
          color: theme.palette.grey[700]
        }
      }
    },
    MuiCard: {
      root: {
        boxShadow: 'none',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: componentLibraryTailwindConfig.theme.colors.sand,

      }
    },
    MuiPaper: {
      root: {
        borderRadius: 'none'
      }
    },
    MuiAutocomplete: {
      root: {
        '&[aria-expanded=true]': {
          color: '#FFFFFF'
        },
        '&[aria-expanded=true] path': {
          fill: '#FFFFFF'
        }
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(9,30,69,0.54)'
      }
    },
    MuiButton: {
      root: {
        borderRadius: 3,
        textTransform: 'none'
      },
      contained: {
        boxShadow: 'none'
      }
    },
    MuiChip: {
      root: {
        borderRadius: 3,
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.primary.dark,
        margin: 4
      }
    },
    MuiSwitch: {
      root: {
        padding: 10
      },
      switchBase: {
        padding: 12
      },
      thumb: {
        backgroundColor: '#FFFFFF',
        height: 14,
        width: 14
      },
      track: {
        borderRadius: 9
      }
    },
    MuiTabs: {
      indicator: {
        transition: 'none'
      }
    },
    MuiTypography: {
      button: {
        textTransform: 'none'
      }
    },
    MuiButtonBase: {
      root: {
        borderRadius: 'none'
      }
    }
  }
}

export const linktreeTheme: BackstageTheme = {
  ...linktreeBase,
  overrides: {
    ...linktreeBase.overrides,
    ...createCustomThemeOverrides(linktreeBase),
  },
}

/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { EntitySwitch, isKind } from '@backstage/plugin-catalog'
import React from 'react'

import ApiPage from './pages/Api'
import ComponentPage from './pages/Component'
import DefaultEntityPage from './pages/Default'
import DomainPage from './pages/Domain'
import GroupPage from './pages/Group'
import SystemPage from './pages/System'
import UserPage from './pages/User'

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={ComponentPage} />
    <EntitySwitch.Case if={isKind('api')} children={ApiPage} />
    <EntitySwitch.Case if={isKind('group')} children={GroupPage} />
    <EntitySwitch.Case if={isKind('user')} children={UserPage} />
    <EntitySwitch.Case if={isKind('system')} children={<SystemPage />} />
    <EntitySwitch.Case if={isKind('domain')} children={<DomainPage />} />

    <EntitySwitch.Case>{DefaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
)

import { Entity } from '@backstage/catalog-model'
import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core'
import { TelemetryComponents } from 'backstage-plugin-telemetry'
import React from 'react'

type TelemetryCriticalitySystemByComponentChartProps = {
  entities: Entity[]
}

export const TelemetryCriticalitySystemByComponentChart: React.FC<TelemetryCriticalitySystemByComponentChartProps> = ({
  entities
}) => {
  return (
    <Card style={{ padding: 0 }}>
      <CardHeader
        title="Criticality Overview"
        titleTypographyProps={{ variant: 'h5' }}
      />
      <CardContent>
        <Grid container direction="row">
          <Grid item md={12}>
            <Typography variant="body2">
              A breakdown of the Components within this System, categorised by Criticality.
            </Typography>
          </Grid>
          <Grid item md={12}>
            <TelemetryComponents.ComponentsByCriticalityPieChart
              entities={entities}
              showTooltip
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

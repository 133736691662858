import React from 'react'
import { EntityDependsOnComponentsCard, EntityDependsOnResourcesCard, EntityLayout } from '@backstage/plugin-catalog'
import { Grid } from '@material-ui/core'
import { EntityConsumedApisCard, EntityProvidedApisCard } from '@backstage/plugin-api-docs'
import { EntityTechdocsContent } from '@backstage/plugin-techdocs'
import { TechDocsAddons } from '@backstage/plugin-techdocs-react'
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid'
import EntityWarningContent from '../content/EntityWarningContent'
import OverviewContent from '../content/OverviewContent'
import CICDContent from '../content/CICDContent'
import TelemetryContentPage from '../content/TelemetryContent'
import { hasTelemetryData } from '../../util/helpers'

const ServiceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <OverviewContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {CICDContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        {EntityWarningContent}
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent>
        <TechDocsAddons>
          <Mermaid config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }} />
        </TechDocsAddons>
      </EntityTechdocsContent>
    </EntityLayout.Route>

    <EntityLayout.Route if={entity => hasTelemetryData(entity)} path="/telemetry" title="Telemetry">
      <TelemetryContentPage />
    </EntityLayout.Route>
  </EntityLayout>
)

export default ServiceEntityPage

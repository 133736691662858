import { Entity } from '@backstage/catalog-model'
import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core'
import { TelemetryComponents } from 'backstage-plugin-telemetry'
import React from 'react'

export type TelemetryCategoryOverviewCardProps = {
  entities: Entity[]
}

export const TelemetryCategoryOverviewCard: React.FC<TelemetryCategoryOverviewCardProps> = ({ entities }) => {
  return (
    <Card>
      <CardHeader title="Telemetry Overview" />
      <CardContent>
        <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="flex-start">
          <Grid item md={12}>
            <Typography variant="body2">
              An overview of the Telemetry check results for the service. For more information, see the <b>Telemetry</b>{' '}
              tab.
            </Typography>
          </Grid>
          <Grid item md={12}>
            <TelemetryComponents.ComponentsByCategoryRadialBarChart
              entities={entities}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

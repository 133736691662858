import { Entity } from '@backstage/catalog-model'
import React, { useState } from 'react'
import { Pie, PieChart, ResponsiveContainer, Sector, Tooltip, TooltipProps } from 'recharts'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { useCustomStyles } from '../../util'
import { Criticality, TCriticality, toColour, toPercent } from '../../util/helpers'
import { categoriseEntitiesByCriticality } from './helpers'

function CustomTooltip({ active, payload }: TooltipProps<ValueType, NameType>) {
  const styles = useCustomStyles()

  if (active && payload && payload[0].payload.entities?.length) {
    return (
      <div className={styles.tooltip}>
        <b style={{ color: toColour(payload[0].payload.colourIndex) }}>Components</b>
        {payload[0].payload.entities.map((entity: string) => (
          <>
            <p style={{ marginTop: '2', marginBottom: '2' }}>{`• ${entity}`}</p>
          </>
        ))}
      </div>
    )
  }

  return null
}

const renderActiveShape = (props: any) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} fontSize="18px">
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={toColour(payload.colourIndex)}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={toColour(payload.colourIndex)}
      />
    </g>
  )
}

export type ComponentsByCriticalityPieChartProps = {
  entities: Entity[]
  height?: string | number
  width?: string | number
  showTooltip?: boolean
}

export const ComponentsByCriticalityPieChart: React.FC<ComponentsByCriticalityPieChartProps> = ({
  entities,
  height = 350,
  width = '100%',
  showTooltip = true
}) => {
  const categorisedEntities = categoriseEntitiesByCriticality(entities)
  const data = Object.entries(categorisedEntities).map(([criticality, criticalityEntities], index: number) => ({
    colourIndex: index,
    entities: criticalityEntities.map(entity => entity.metadata.name),
    name: criticality,
    value: toPercent(criticalityEntities.length, entities.length)
  }))

  const [activeIndex, setActiveIndex] = useState(
    Criticality[data.find(({ value }) => value)?.name as TCriticality | undefined || 'Low']
  )

  return (
    <ResponsiveContainer width={width} height={height}>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={90}
          outerRadius={100}
          fill={toColour(activeIndex)}
          dataKey="value"
          onMouseEnter={(_: object, index: number) => setActiveIndex(index)}
        />
        {showTooltip && <Tooltip content={<CustomTooltip />} position={{ x: 80, y: 30 }} />}
      </PieChart>
    </ResponsiveContainer>
  )
}

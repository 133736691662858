import { TabbedLayout } from '@backstage/core-components'
import { useEntityList } from '@backstage/plugin-catalog-react'
import { Card, Grid } from '@material-ui/core'
import React from 'react'
import { ComponentsByCategoryCardContent } from '../ComponentsByCategory'
import { ComponentsByCriticalityCardContent } from '../ComponentsByCriticality'

export const CatalogTableComponentChartsCard: React.FC = () => {
  const { entities } = useEntityList()

  return (
    <Card>
      <TabbedLayout>
        <TabbedLayout.Route title="Categories" path="categories">
          <Grid container spacing={3} direction="column">
            <Grid item>
              <ComponentsByCategoryCardContent
                chartStyle={{
                  containerHeight: 250
                }}
                entities={entities}
                showLegend={false}
                showTitle={false}
                showDescription={false}
              />
            </Grid>
          </Grid>
        </TabbedLayout.Route>
        <TabbedLayout.Route title="Criticality" path="criticality">
          <Grid container spacing={3} direction="column">
            <Grid item>
              <ComponentsByCriticalityCardContent
                chartStyle={{
                  containerHeight: 250
                }}
                entities={entities}
                showLegend
                showTitle={false}
                showDescription={false}
              />
            </Grid>
          </Grid>
        </TabbedLayout.Route>
      </TabbedLayout>
    </Card>
  )
}

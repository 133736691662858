import { Result } from '@linktr.ee/telemetry-api-client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core'
import React from 'react'
import { analyseResults } from '../../util'
import { LinkButton } from '@backstage/core-components'

const TableRowFormattedResult: React.FC<{ isLatestResult: boolean; result: Result }> = ({ isLatestResult, result }) => {
  return (
    <TableRow>
      <TableCell>
        <b>{result.name}</b> <div dangerouslySetInnerHTML={{ __html: result.description }} />
      </TableCell>
      <TableCell>{result.success ? 'Passed' : 'Failed'}</TableCell>
      <TableCell>
        {!result.success && result.actions?.length && isLatestResult ? (
          <Tooltip title={result.actions[0].description}>
            <LinkButton color="primary" to="#" variant="contained">
              Run Fix
            </LinkButton>
          </Tooltip>
        ) : (
          <Link href="">Usage</Link>
        )}
      </TableCell>
    </TableRow>
  )
}

export type ResultsTableAccordionProps = {
  id: string
  isLatestResult: boolean
  results: Result[]
  expanded?: boolean
}

/**
 * An accordion for displaying results of Telemetry checks
 */
export const ResultsTableAccordion: React.FC<ResultsTableAccordionProps> = ({
  id,
  isLatestResult,
  expanded,
  results
}) => {
  const { numberOfChecks, numberOfPassingChecks } = analyseResults(results)
  const actionableChecks = results.filter(result => !result.success && result.actions?.length)
  const accordionTitle = actionableChecks.length
    ? `🪄 ${numberOfPassingChecks}/${numberOfChecks} checks are passing`
    : `${numberOfPassingChecks}/${numberOfChecks} checks are passing`

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary>
        <Typography variant="button">{accordionTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="row">
          <TableContainer>
            <Table size="small" aria-label="simple-table">
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Result</TableCell>
                  <TableCell>Remediation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((result, index) => (
                  <TableRowFormattedResult
                    isLatestResult={isLatestResult}
                    result={result}
                    key={`telemetry-category-results-accordian-${id}-${index}`}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

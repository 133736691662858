import React from 'react'
import { EntitySwitch, isComponentType } from '@backstage/plugin-catalog'
import ServiceEntityPage from './Service'
import WebsiteEntityPage from './Website'

const ComponentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>{ServiceEntityPage}</EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>{WebsiteEntityPage}</EntitySwitch.Case>

    <EntitySwitch.Case>{ServiceEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
)

export default ComponentPage

import { Card, CardContent } from '@material-ui/core'
import React from 'react'

export const FullWidthBanner: React.FC<{ children: JSX.Element }> = ({ children }) => {
  return (
    <Card>
      <CardContent style={{ backgroundColor: '#8129D9' }}>{children}</CardContent>
    </Card>
  )
}

import { Entity } from '@backstage/catalog-model'
import { Card } from '@material-ui/core'
import { TelemetryComponents } from 'backstage-plugin-telemetry'
import React from 'react'

type TelemetryCategorySystemOverviewCardProps = {
  chartStyle?: {
    containerHeight?: number
  }
  relatedEntities: Entity[]
}

export const TelemetryCategorySystemOverviewCard: React.FC<TelemetryCategorySystemOverviewCardProps> = ({
  chartStyle,
  relatedEntities
}) => {
  return (
    <Card>
      <TelemetryComponents.ComponentsByCategoryCardContent
        entities={relatedEntities}
        chartStyle={chartStyle}
      />
    </Card>
  )
}

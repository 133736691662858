import { Entity } from '@backstage/catalog-model'
import { useApi } from '@backstage/core-plugin-api'
import { catalogApiRef, getEntityRelations } from '@backstage/plugin-catalog-react'
import { Card } from '@material-ui/core'
import { TelemetryComponents } from 'backstage-plugin-telemetry'
import React, { useEffect, useState } from 'react'

type TelemetryDomainCriticalityBarCardProps = {
  systemRelations: Entity[]
}

export const TelemetryDomainCriticalityBarCard: React.FC<TelemetryDomainCriticalityBarCardProps> = ({
  systemRelations
}) => {
  const catalogApi = useApi(catalogApiRef)
  const [components, setComponents] = useState([] as Entity[])

  useEffect(() => {
    async function getComponents() {
      const results = (await Promise.all(systemRelations.map(async (system): Promise<Entity[] | undefined> => {
        const componentEntityNames = [...new Set(getEntityRelations(system, 'hasPart').map((entity) => entity.name))]
        return componentEntityNames.length && !components.some((result) => Object.keys(result).includes(system.metadata.name))
          ? (await catalogApi.getEntities({ filter: { 'metadata.name': componentEntityNames } })).items
          : undefined
      }))).filter(Boolean).flat() as Entity[]

      setComponents([...components, ...results])
    }

    if (systemRelations.length && components.length === 0) getComponents()
  }, [systemRelations, components, catalogApi])

  return (
    <Card>
      <TelemetryComponents.ComponentsByCriticalityCardContent
        entities={components}
        title="Telemetry"
        description="The overall Criticality of Systems within this Domain, and their adherence to Telemetry checks. This overview shows where more effort may be required for components across the System."
      />
    </Card>
  )
}

import { Entity } from '@backstage/catalog-model'
import React from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { Criticality, TCriticality, toColour, toHistorgram } from '../../util/helpers'
import { useCustomStyles } from '../../util/styles'
import { ComponentResult, resolveComponentResults } from './helpers'

const formatData = (componentResults: ComponentResult[]) => {
  const data = Object.keys(Criticality).map((criticality) => ({ criticality, data: [] as ComponentResult[] }))

  componentResults.forEach((result) => {
    (data[Criticality[result.criticality as TCriticality]] as unknown as Record<string, number>)[result.name] = result.percent
    data[Criticality[result.criticality as TCriticality]].data.push(result)
  })

  return data
}

function CustomTooltip({ active, payload, systems }: TooltipProps<ValueType, NameType> & { systems: string[] }) {
  const styles = useCustomStyles()

  if (active && payload && payload.length) {
    const data = (payload[0].payload.data || []) as ComponentResult[]
    const histogram = Object.entries(toHistorgram(data)) as unknown as [number, number][]

    return (
      <div className={styles.tooltip}>
        <b>{payload[0].payload.criticality}</b>
        {
          data.length <= 5
            ? data.map(({ name, percent, system }: ComponentResult) => {
              return (
                <div style={{ padding: '5px', color: toColour(systems.findIndex((s) => s === system)) }} key={`telemetry-domain-criticality-bar-chart-tooltip-${name}`}>
                  <b>{name}</b>: {percent}%
                </div>
              )
            })
            : <div style={{ padding: '5px' }} >
              {histogram.map(([bucket, count], index) => {
                const nextThreshold = histogram[index+1]?.[0]
                return (
                  <div key={`telemetry-domain-criticality-bar-chart-tooltip-${bucket}`}>
                    <b style={{ float: 'left' }}>{bucket}{nextThreshold ? ` - ${nextThreshold - 1}` : ''}%</b>
                    <span style={{ float: 'right' }}>{count} {count === 1 ? 'Service' : 'Services'}</span>
                    <div style={{ clear: 'both' }} />
                  </div>
                )
              })}
            </div>
        }
      </div>
    )
  }

  return null
}

export type ComponentsByCriticalityBarChartProps = {
  entities: Entity[]
  chartStyle?: {
    containerHeight?: number
  }
  showTooltip?: boolean
  showLegend?: boolean
}

export const ComponentsByCriticalityBarChart: React.FC<ComponentsByCriticalityBarChartProps> = ({
  entities,
  chartStyle,
  showLegend = true,
  showTooltip = true
}) => {
  const componentResults = resolveComponentResults(entities)
  const systems = [...new Set(componentResults.map(({ system }) => system))]

  return (
    <ResponsiveContainer width="100%" height={chartStyle?.containerHeight ?? 350}>
      <BarChart
        data={formatData(componentResults)}
      >
        <CartesianGrid />
        <XAxis
          type="category"
          dataKey="criticality"
          name="Criticality"
        />
        <YAxis
          type="number"
          name="Passing Checks"
          unit="%"
          domain={[0, 100]}
          allowDuplicatedCategory={false}
        />
        {showLegend && <Legend payload={
          systems.map((system, index) => ({
            color: toColour(index),
            type: 'rect',
            value: system
          }))
        } />}
        {showTooltip && <Tooltip content={<CustomTooltip systems={systems} />} />}
        {componentResults.map((result, index) => {
          return (
            <Bar
              dataKey={result.name ?? 0}
              fill={toColour(systems.findIndex((system) => result.system === system))}
              key={`telemetry-components-by-criticality-bar-chart-${index}`}
            />
          )
        })}
      </BarChart>
    </ResponsiveContainer>
  )
}

import React from 'react'
import { EntityLayout } from '@backstage/plugin-catalog'
import OverviewContent from '../content/OverviewContent'
import { EntityTechdocsContent } from '@backstage/plugin-techdocs'
import CICDContent from '../content/CICDContent'

const DefaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <OverviewContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {CICDContent}
    </EntityLayout.Route>
  </EntityLayout>
)

export default DefaultEntityPage

import { makeStyles } from '@material-ui/core'

export const useCustomStyles = makeStyles(theme => ({
  tooltip: {
    margin: 0,
    lineHeight: '24px',
    border: '1px solid',
    minWidth: '200px',
    backgroundColor: theme.palette.background.paper,
    padding: '10px'
  }
}))

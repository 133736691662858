import { EntityLinksCard } from '@backstage/plugin-catalog'
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph'
import { useEntity } from '@backstage/plugin-catalog-react'
import { Results } from '@linktr.ee/telemetry-api-client'
import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { TelemetryCategoryOverviewCard } from '../../custom/telemetry/cards/TelemetryCategoryOverviewCard'
import { TelemetryComponentAboutCard } from '../../custom/telemetry/cards/TelemetryComponentAboutCard'
import { getActionableChecks, hasTelemetryData } from '../../util/helpers'
import EntityWarningContent from './EntityWarningContent'
import { FullWidthBanner } from '../../util/Banner'
import { LinkButton } from '@backstage/core-components'

const ActionButton: React.FC = () => {
  return (
    <LinkButton style={{ backgroundColor: '#F9FCFC', color: '#000' }} variant="contained" to="telemetry">
      Go to Telemetry
    </LinkButton>
  )
}

const OverviewContent: React.FC = () => {
  const { entity } = useEntity()
  const telemetryData = hasTelemetryData(entity)
    ? ((entity.metadata['linktr.ee/telemetry'] as unknown) as Results)
    : undefined

  const actionableChecks = telemetryData ? getActionableChecks(telemetryData.results) : undefined

  return (
    <Grid container spacing={3} alignItems="stretch">
      {actionableChecks?.length ? (
        <Grid item md={12}>
          <FullWidthBanner>
            <Grid container direction="row">
              <Grid item md={10} style={{ alignItems: 'center', display: 'flex' }}>
                <Typography style={{ color: '#fff' }} variant="body2">
                  🚨 <b>Heads up!</b> Some failing checks can be remediated with one click! Check out Telemetry for more
                  details.
                </Typography>
              </Grid>
              <Grid item md={2} style={{ justifyContent: 'end', display: 'flex' }}>
                {actionableChecks.map(result => result.actions?.length && <ActionButton />)}
              </Grid>
            </Grid>
          </FullWidthBanner>
        </Grid>
      ) : null}
      {EntityWarningContent}
      <Grid item md={6}>
        <TelemetryComponentAboutCard entity={entity} />
      </Grid>
      {/* TODO: Re-enable once error is fixed */}
      {/* <EntitySwitch>
        <EntitySwitch.Case if={isPagerDutyAvailable}>
          <Grid item md={6}>
            <EntityPagerDutyCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch> */}
      {telemetryData && (
        <Grid item md={6} xs={12}>
          <TelemetryCategoryOverviewCard entities={[entity]} />
        </Grid>
      )}
      <Grid item md={6} xs={12}>
        <EntityCatalogGraphCard variant="gridItem" height={400} />
      </Grid>
      <Grid item md={4} xs={12}>
        <EntityLinksCard />
      </Grid>
    </Grid>
  )
}

export default OverviewContent

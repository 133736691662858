import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api'

import { rootRouteRef } from './routes'

export const telemetryPlugin = createPlugin({
  id: 'telemetry',
  routes: {
    root: rootRouteRef
  }
})

export const TelemetryPage = telemetryPlugin.provide(
  createRoutableExtension({
    name: 'TelemetryPage',
    component: () => import('./components/TelemetryPage').then(m => m.TelemetryPage),
    mountPoint: rootRouteRef
  })
)

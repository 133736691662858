import { useEntity } from '@backstage/plugin-catalog-react'
import { Action, Results } from '@linktr.ee/telemetry-api-client'
import { Grid, Link, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { TelemetryCategoryResultsCard } from '../../custom/telemetry/cards/TelemetryCategoryResultsCard'
import {
  categoriseHistory,
  categoriseResults,
  getActionableChecks,
  getGitHubMetadataFromEntity,
  toColour
} from '../../util/helpers'
import { LinkButton } from '@backstage/core-components'
import {
  AlertApi,
  ConfigApi,
  ErrorApi,
  ErrorApiError,
  IdentityApi,
  alertApiRef,
  configApiRef,
  errorApiRef,
  identityApiRef,
  useApi
} from '@backstage/core-plugin-api'
import { Entity } from '@backstage/catalog-model'
import { setTokenCookie } from '../../../cookieAuth'
import { FullWidthBanner } from '../../util/Banner'

type CatalogApiDefinitions = {
  identityApi: IdentityApi
  alertApi: AlertApi
  errorApi: ErrorApi
  configApi: ConfigApi
}

async function runTelemetryAction(
  entity: Entity,
  { alertApi, configApi, errorApi, identityApi }: CatalogApiDefinitions,
  { actionId, context, description }: { actionId: string; context: string; description: string }
): Promise<void> {
  const backendUrl = configApi.getString('backend.baseUrl')
  const { runId } = (entity.metadata['linktr.ee/telemetry'] as unknown) as Results

  try {
    alertApi.post({ message: `Running action: ${description}`, severity: 'info' })

    const { owner, repo } = getGitHubMetadataFromEntity(entity)

    await setTokenCookie(`${backendUrl}/api/run-action/run`, identityApi, {
      body: JSON.stringify({
        actionId,
        context,
        owner,
        repo,
        runId
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
  } catch (e) {
    errorApi.post(e as ErrorApiError)
  }
}

const ActionButton: React.FC<{
  entity: Entity
  action: Action
  actionButtonDisabled: boolean
  setActionButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ entity, action, actionButtonDisabled, setActionButtonDisabled }) => {
  const catalogApiDefinitions = {
    alertApi: useApi(alertApiRef),
    configApi: useApi(configApiRef),
    errorApi: useApi(errorApiRef),
    identityApi: useApi(identityApiRef)
  }

  const onClick = async () => {
    setActionButtonDisabled(true)
    await runTelemetryAction(entity, catalogApiDefinitions, {
      actionId: action.id,
      context: action.context || '',
      description: action.description
    })
  }

  return (
    <LinkButton
      disabled={actionButtonDisabled}
      onClick={onClick}
      style={{ backgroundColor: '#5DB184', color: '#fff' }}
      variant="contained"
      to="#"
    >
      ✨ {action.description} ✨
    </LinkButton>
  )
}

const TelemetryContentPage: React.FC = () => {
  const { entity } = useEntity()
  const { results, history, updatedAt } = (entity.metadata['linktr.ee/telemetry'] as unknown) as Results
  const categorisedResults = categoriseResults(results)
  const categorisedHistory = categoriseHistory(history)
  const actionableChecks = getActionableChecks(results)
  const focusActionableCheck = actionableChecks[0]

  const [activeCategory, setActiveCategory] = React.useState('')
  const [actionButtonDisabled, setActionButtonDisabled] = useState(false)

  return (
    <>
      <Grid container direction="row" spacing={4} style={{ marginBottom: '10px' }}>
        {actionableChecks.length && !actionButtonDisabled ? (
          <Grid item md={12}>
            <FullWidthBanner>
              <Grid container direction="row">
                <Grid item md={8} style={{ alignItems: 'center', display: 'flex' }}>
                  <Typography style={{ color: '#fff' }} variant="body2">
                    ✨ You can fix the failing <b>{focusActionableCheck.name}</b> check now by creating a one-click pull
                    request!
                    {actionableChecks.length > 1 ? (
                      <>
                        <br />
                        <br />
                        There are other failing checks that can also be fixed with one-click. Look for a 🪄 in the tables
                        below.
                      </>
                    ) : null}
                  </Typography>
                </Grid>
                <Grid container md={4} style={{ alignItems: 'center', justifyContent: 'end', display: 'flex' }}>
                  {actionableChecks.map(result => {
                    const targetContext = result.actions![0].context || ''
                    const targetActions = result.actions?.filter(action => action.context === targetContext)
                    return (
                      targetActions?.length &&
                      targetActions.map(action => (
                        <Grid item>
                          <ActionButton
                            entity={entity}
                            action={action}
                            actionButtonDisabled={actionButtonDisabled}
                            setActionButtonDisabled={setActionButtonDisabled}
                          />
                        </Grid>
                      ))
                    )
                  })}
                </Grid>
              </Grid>
            </FullWidthBanner>
          </Grid>
        ) : null}
        {Object.entries(categorisedResults).map(([categoryName, entry], index) => {
          const id = categoryName.toLocaleLowerCase().replace(/\W/g, '-')
          return (
            <TelemetryCategoryResultsCard
              id={id}
              key={`telemetry-category-results-${id}`}
              title={categoryName}
              blurb={entry.description}
              colour={toColour(index)}
              isActive={activeCategory === categoryName}
              results={{
                entity: entity.metadata.name,
                results: entry.results,
                history: categorisedHistory[categoryName],
                updatedAt
              }}
              onMouseOver={() => setActiveCategory(categoryName)}
            />
          )
        })}
      </Grid>
      <Typography variant="body2">
        Got feedback on Telemetry?{' '}
        <Link href="https://linktreehq.slack.com/archives/C058TA69RRR">
          <b>Let us know in #ask-developer-platform!</b>
        </Link>
      </Typography>
    </>
  )
}

export default TelemetryContentPage

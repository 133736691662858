/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { SidebarSearchModal } from '@backstage/plugin-search'
import { Settings as SidebarSettings } from '@backstage/plugin-user-settings'
import { Link, makeStyles } from '@material-ui/core'
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline'
import DomainIcon from '@material-ui/icons/Apartment'
import SystemIcon from '@material-ui/icons/Category'
import { default as ApiIcon, default as ExtensionIcon } from '@material-ui/icons/Extension'
import HomeIcon from '@material-ui/icons/Home'
import LibraryBooks from '@material-ui/icons/LibraryBooks'
import ComponentIcon from '@material-ui/icons/Memory'
import MapIcon from '@material-ui/icons/MyLocation'
import NetworkCheckIcon from "@material-ui/icons/NetworkCheck"
import UserIcon from '@material-ui/icons/Person'
import ResourceIcon from '@material-ui/icons/Work'
import React, { PropsWithChildren, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import LogoFull from './LogoFull'
import LogoIcon from './LogoIcon'

import {
  GroupIcon, Sidebar, sidebarConfig,
  SidebarContext, SidebarDivider, SidebarItem, SidebarPage, SidebarScrollWrapper, SidebarSpace, SidebarSubmenu,
  SidebarSubmenuItem
} from '@backstage/core-components'

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24
  }
})

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles()
  const { isOpen } = useContext(SidebarContext)

  return (
    <div className={classes.root}>
      <Link component={NavLink} to="/" underline="none" className={classes.link}>
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  )
}

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarSearchModal />
      <SidebarDivider />
      {/* Global nav, not org-specific */}
      <SidebarItem icon={HomeIcon} to="/catalog" text="Home">
        <SidebarSubmenu title="Catalog">
          <SidebarSubmenuItem title="Domains" to="catalog?filters[kind]=domain" icon={DomainIcon} />
          <SidebarSubmenuItem title="Systems" to="catalog?filters[kind]=system" icon={SystemIcon} />
          <SidebarSubmenuItem title="Components" to="catalog?filters[kind]=component" icon={ComponentIcon} />
          <SidebarSubmenuItem title="APIs" to="catalog?filters[kind]=api" icon={ApiIcon} />
          <SidebarDivider />
          <SidebarSubmenuItem title="Resources" to="catalog?filters[kind]=resource" icon={ResourceIcon} />
          <SidebarDivider />
          <SidebarSubmenuItem title="Groups" to="catalog?filters[kind]=group" icon={GroupIcon} />
          <SidebarSubmenuItem title="Users" to="catalog?filters[kind]=user" icon={UserIcon} />
        </SidebarSubmenu>
      </SidebarItem>
      <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
      <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
      <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
      {/* End global nav */}
      <SidebarDivider />
      <SidebarScrollWrapper>
        <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
      </SidebarScrollWrapper>
      <SidebarItem icon={NetworkCheckIcon} to='/telemetry' text='Telemetry' />
      <SidebarSpace />
      <SidebarDivider />
      <SidebarSettings />
    </Sidebar>
    {children}
  </SidebarPage>
)

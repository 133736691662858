import { History, Result, Results } from '@linktr.ee/telemetry-api-client'
import { Entity } from '@backstage/catalog-model'

const criticalityTypes = ['Low', 'Medium', 'High', 'Critical'] as const
export type TCriticality = typeof criticalityTypes[number]

export function isCriticality(str: string): str is TCriticality {
  return criticalityTypes.includes(str as TCriticality)
}

export const Criticality: { [P in TCriticality]: number } = {
  Low: 0,
  Medium: 1,
  High: 2,
  Critical: 3
}

const colours = ['#d717e7', '#ff007d', '#ff6900', '#d2b600', '#56e643']

export function hasTelemetryData(entity: Entity): boolean {
  return 'linktr.ee/telemetry' in entity.metadata
}

export function toPercent(value: any, total: any): number {
  return (value / total) * 100
}

export function toColour(value: number): string {
  return colours[value % colours.length]
}

export const getAllResultCategories = (results: Result[]): string[] => [
  ...new Set((results ?? []).map(result => (result.categories ?? []).map(category => category.name)).flat())
]

export const categoriseResults = (results: Result[]): Record<string, { description: string; results: Result[] }> => {
  const categorisedResults: Record<string, { description: string; results: Result[] }> = {}

  results.forEach((result: Result) =>
    result.categories.forEach(category => {
      const { name, description } = category
      if (!categorisedResults[name]) categorisedResults[name] = { description, results: [] }
      categorisedResults[name].results.push(result)
    })
  )

  return categorisedResults
}

export const categoriseHistory = (history: History[]): Record<string, History[]> => {
  const categorisedHistory: Record<string, History[]> = {}

  history.forEach(({ results: historicalResults, updatedAt }) => {
    const categorisedResults = categoriseResults(historicalResults)
    Object.entries(categorisedResults).forEach(([categoryName, { results }]) => {
      if (!(categoryName in categorisedHistory)) categorisedHistory[categoryName] = []
      categorisedHistory[categoryName].push({ updatedAt, results })
    })
  })

  return categorisedHistory
}

export const calculateSuccessPercentage = (entity: Entity): number => {
  const telemetryData = (entity.metadata['linktr.ee/telemetry'] as unknown) as Results
  return toPercent(telemetryData.results.filter(result => result.success).length, telemetryData.results.length)
}

export type CategorisedEntities = { [P in TCriticality]: Entity[] }

export function categoriseEntitiesByCriticality(entities: Entity[]): CategorisedEntities {
  const result: CategorisedEntities = {
    Low: [],
    Medium: [],
    High: [],
    Critical: []
  }

  entities.forEach((entity: Entity) => {
    const criticality: TCriticality | undefined =
      (entity.metadata.annotations?.['linktr.ee/criticality'] as TCriticality) ?? undefined
    if (isCriticality(criticality)) result[criticality].push(entity)
  })

  return result
}

export const getActionableChecks = (results: Result[]): Result[] => {
  return results.filter(result => !result.success && result.actions?.length)
}

export const getGitHubMetadataFromEntity = (entity: Entity): { owner: string; repo: string } => {
  return {
    owner:
      entity.metadata.annotations?.['backstage.io/source-location'].match(
        new RegExp(/(?<=github.com\/)(\w+)(?=\/)/)
      )?.[0] ?? 'blstrco',
    repo:
      entity.metadata.annotations?.['backstage.io/source-location'].match(
        new RegExp(/(?<=blstrco\/)(.*)(?=\/t)/)
      )?.[0] ?? entity.metadata.name
  }
}

import { EntityLayout } from '@backstage/plugin-catalog'
import { EntityGroupProfileCard, EntityMembersListCard, EntityOwnershipCard } from '@backstage/plugin-org'
import { Grid } from '@material-ui/core'
import React from 'react'
import EntityWarningContent from '../content/EntityWarningContent'

const GroupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {EntityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
)

export default GroupPage

import { History, Result, Results } from '@linktr.ee/telemetry-api-client'
import { Entity } from '@backstage/catalog-model'

const colours = [
  '#d717e7',
  '#ff007d',
  '#ff6900',
  '#d2b600',
  '#56e643'
]

const criticalities = ['Low', 'Medium', 'High', 'Critical'] as const
export type TCriticality = typeof criticalities[number]

export function isCriticality(str: string): str is TCriticality {
  return criticalities.includes(str as TCriticality)
}

export const Criticality: { [P in TCriticality]: number } = {
  Low: 0,
  Medium: 1,
  High: 2,
  Critical: 3
}

export function hasTelemetryData(entity: Entity): boolean {
  return 'linktr.ee/telemetry' in entity.metadata
}

export function hasTelemetryDataSets(entity: Entity): boolean {
  return 'linktr.ee/telemetry-data-sets' in entity.metadata
}

export function resolveTelemetryData(entity: Entity): Results | undefined {
  return hasTelemetryData(entity) ? entity.metadata['linktr.ee/telemetry'] as unknown as Results : undefined
}

export function resolveTelemetryDataSets(entity: Entity): any | undefined {
  return hasTelemetryDataSets(entity) ? entity.metadata['linktr.ee/telemetry-data-sets'] : undefined
}

export function toPercent(value: any, total: any): number {
  return Math.round((value / total) * 100)
}

export function toColour(value: number): string {
  return colours[value % colours.length]
}

export function toHistorgram(analysis: Pick<ResultsAnalysis, 'percent'>[]): { [bucket: number]: number } {
  const buckets = [0, 25, 50, 75, 100]
  const histogram: { [bucket: number]: number } = {}

  buckets.forEach((bucket) => {
    histogram[bucket] = analysis.filter(({ percent }) => percent >= bucket).length
  })

  buckets.forEach((bucket, index) => {
    if (index === buckets.length - 1) return
    histogram[bucket] -= histogram[buckets[index + 1]]
  })

  return histogram
}

export type ResultsAnalysis = {
  /**
   * The total number of checks
   */
  numberOfChecks: number
  /**
   * The number of passing checks
   */
  numberOfPassingChecks: number
  /**
   * The percentage value of passing checks
   */
  percent: number
}

export const analyseResults = (results: Result[]): ResultsAnalysis => {
  const numberOfPassingChecks = results.filter((result) => result.success).length
  const numberOfChecks = results.length
  return {
    numberOfChecks,
    numberOfPassingChecks,
    percent: toPercent(numberOfPassingChecks, numberOfChecks)
  }
}

export const analyseEntityResults = (entity: Entity): ResultsAnalysis => {
  const telemetryData = resolveTelemetryData(entity)
  return analyseResults(telemetryData?.results ?? [])
}

export const getAllResultCategories = (results: Result[]): string[] => [
  ...new Set((results).map(({ categories }) => categories.map(({ name }) => name)).flat())
]

export type CategorisedResults = {
  [category: string]: {
    description: string
    results: Result[]
  }
}

/**
 * @returns `{ 'best-practice': { description: '...', results: [result1, result2, ... ]}, ... }`
 */
export const categoriseResults = (results: Result[]): CategorisedResults => {
  const categorisedResults: CategorisedResults = {}

  results.forEach((result: Result) =>
    result.categories.forEach(
      (category) => {
        const { name, description } = category
        if (!categorisedResults[name]) categorisedResults[name] = { description, results: [] }
        categorisedResults[name].results.push(result)
      }
    )
  )

  return categorisedResults
}

/**
 * @returns `{ 'best-practice': { description: '...', results: [result1, result2, ... ]}, ... }`
 */
export const categoriseEntityResults = (entity: Entity): CategorisedResults => {
  const telemetryData = resolveTelemetryData(entity)
  return categoriseResults(telemetryData?.results ?? [])
}

/**
 * @returns `{ 'best-practice': { description: '...', results: [result1, result2, ... ]}, ... }`
 */
export const categoriseEntitiesResults = (entities: Entity[]): CategorisedResults => {
  const results = entities.map((entity) => resolveTelemetryData(entity)?.results ?? []).flat()
  return categoriseResults(results)
}

/**
 * @returns `{ 'best-practice': [{ updatedAt: xxxxx, results: [result1, result2, ... ] }, ...] }`
 */
export const categoriseHistory = (history: History[]): Record<string, History[]> => {
  const categorisedHistory: Record<string, History[]> = {}

  history.forEach(({ results: historicalResults, updatedAt }) => {
    const categorisedResults = categoriseResults(historicalResults)
    Object.entries(categorisedResults).forEach(([categoryName, { results }]) => {
      if (!(categoryName in categorisedHistory)) categorisedHistory[categoryName] = []
      categorisedHistory[categoryName].push({ updatedAt, results })
    })
  })

  return categorisedHistory
}

/**
 * @returns `{ entityA: [result1, result2, ...] }`
 */
export const resultsByEntityName = (entities: Entity[]): { [entityName: string]: Result[] } => {
  const entityResults: { [entityName: string]: Result[] } = {}

  entities.forEach((entity: Entity) => {
    const results = resolveTelemetryData(entity)?.results ?? []
    entityResults[entity.metadata.name] = entity.metadata.name in entityResults ? [...entityResults[entity.metadata.name], ...results] : results || []
  })

  return entityResults
}

/**
 * @returns `{ entityA: { best-practice: { description: '...', results: [result1, result2, ...] } }}`
 */
export const categoriseResultsByEntityName = (
  entityResults: Record<string, Result[]>
): Record<string, Record<string, { description: string; results: Result[] }>> => {
  const categorisedResultsByEntity: Record<string, Record<string, { description: string; results: Result[] }>> = {}

  Object.keys(entityResults).forEach((entity) => {
    const categorisedResults = categoriseResults(entityResults[entity])
    categorisedResultsByEntity[entity] = categorisedResults
  })

  return categorisedResultsByEntity
}

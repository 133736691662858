import { Entity } from '@backstage/catalog-model'
import React from 'react'
import { Legend, PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { analyseResults, CategorisedResults, categoriseEntitiesResults, ResultsAnalysis, toColour } from '../../util/helpers'
import { useCustomStyles } from '../../util/styles'

function CustomTooltip({ active, payload, variant }: TooltipProps<ValueType, NameType> & { variant: 'ratio' | 'percent' }) {
  const styles = useCustomStyles()

  if (active && payload) {
    const { name, numberOfChecks, numberOfPassingChecks } = payload[0].payload
    return (
      <div className={styles.tooltip}>
        <b>{name}</b>
        <br />
        <b>Passed</b>: {variant === 'ratio' ? `${numberOfPassingChecks}/${numberOfChecks}` : `${payload[0].value}%`}
      </div>
    )
  }

  return null
}

const formatData = (data: CategorisedResults): ComponentsByCategoryRadialBarChartData[] => {
  return Object.entries(data).map(([name, { results }], index) => ({
    ...analyseResults(results),
    name,
    fill: toColour(index)
  }))
}

type ComponentsByCategoryRadialBarChartData = ResultsAnalysis & {
  /**
   * The name of the category
   */
  name: string
  /**
   * The hexadecimal colour for the bar
   */
  fill: string
}

export type ComponentsByCategoryRadialBarChartProps = {
  entities: Entity[]
  /**
   * The data to be displayed on the chart
   */
  chartStyle?: {
    containerHeight?: number
    horizontalAlignment?: string
    innerRadius?: string
    outerRadius?: string
  }
  /**
   * Whether to display the legend
   */
  showLegend?: boolean
  /**
   * Whether to display the tooltip
   */
  showTooltip?: boolean
  toolTipVariant?: 'ratio' | 'percent'
  height?: string | number
  width?: string | number
}

export const ComponentsByCategoryRadialBarChart: React.FC<ComponentsByCategoryRadialBarChartProps> = ({
  entities,
  chartStyle,
  showLegend = true,
  showTooltip = true,
  toolTipVariant = 'ratio',
  height = 350,
  width = '100%'
}) => {
  const categorisedResults = categoriseEntitiesResults(entities)
  const data = formatData(categorisedResults)

  return (
    <ResponsiveContainer width={width} height={height}>
      <RadialBarChart
        cy="38%"
        innerRadius={chartStyle?.innerRadius ?? '20%'}
        barSize={8}
        data={data}
      >
        <PolarAngleAxis type="number" domain={[0, 100]} dataKey="percent" angleAxisId={0} tick={false} />
        <RadialBar label={false} background dataKey="percent" style={{ position: 'relative' }} />
        {showTooltip && <Tooltip content={<CustomTooltip variant={toolTipVariant} />} />}
        {showLegend && <Legend iconSize={12} layout="horizontal" wrapperStyle={{ lineHeight: '24px' }} />}
      </RadialBarChart>
    </ResponsiveContainer>
  )
}

import React from 'react'
import { Entity } from '@backstage/catalog-model'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import { MarkdownContent } from '@backstage/core-components'
import { AboutField } from '@backstage/plugin-catalog'
import { AboutCardWithChildren } from '../../AboutCardWithChildren'

const useStyles = makeStyles({
  description: {
    wordBreak: 'break-word'
  },
  telemetryHeading: {
    fontSize: '20px',
    fontWeight: 'bold'
  }
})

function removeLinktreePrefix(key: string): string {
  return key.split('/')[1]
}

type AboutCardWithTelemetryContentProps = {
  entity: Entity
}

/**
 * A component for displaying the default AboutCard, extended with our service attributes gathered through Telemetry.
 *
 * Any attribute prepended with 'linktr.ee/' will be displayed automatically
 *
 * @param props
 * @returns
 */
export function TelemetryContent(props: AboutCardWithTelemetryContentProps) {
  const { entity } = props
  const classes = useStyles()

  const RenderLinktreeTelemetryFields = () => {
    const fields = entity.metadata.annotations
      ? Object.entries(entity.metadata.annotations)
          .filter(([key]) => key.startsWith('linktr.ee/'))
          .sort(([a], [b]) => a.localeCompare(b))
      : []

    return (
      <>
        <Divider />
        {fields.map(([key, value]) => (
          <AboutField label={removeLinktreePrefix(key)} value={value} gridSizes={{ xs: 12, sm: 4, lg: 2 }} />
        ))}
      </>
    )
  }

  return (
    <Grid container>
      <AboutField label="" gridSizes={{ xs: 12 }}>
        <MarkdownContent className={classes.telemetryHeading} content="Service Attributes" />
      </AboutField>
      <RenderLinktreeTelemetryFields />
    </Grid>
  )
}

type TelemetryComponentAboutCardProps = {
  entity: Entity
}

export const TelemetryComponentAboutCard: React.FC<TelemetryComponentAboutCardProps> = ({ entity }) => {
  return <AboutCardWithChildren children={<TelemetryContent entity={entity} />} />
}

import React from 'react'
import { EntityDependsOnComponentsCard, EntityDependsOnResourcesCard, EntityLayout } from '@backstage/plugin-catalog'
import OverviewContent from '../content/OverviewContent'
import CICDContent from '../content/CICDContent'
import { Grid } from '@material-ui/core'
import EntityWarningContent from '../content/EntityWarningContent'
import { EntityTechdocsContent } from '@backstage/plugin-techdocs'

const WebsiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <OverviewContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {CICDContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        {EntityWarningContent}
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>
  </EntityLayout>
)

export default WebsiteEntityPage

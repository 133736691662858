import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API
} from '@backstage/catalog-model'
import { EntityAboutCard, EntityHasSystemsCard, EntityLayout } from '@backstage/plugin-catalog'
import { Direction, EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph'
import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react'
import { EntityTechdocsContent } from '@backstage/plugin-techdocs'
import { TechDocsAddons } from '@backstage/plugin-techdocs-react'
import { Grid } from '@material-ui/core'
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid'
import React from 'react'
import {
  TelemetryDomainCriticalityBarCard
} from '../../custom/telemetry/cards/TelemetryDomainCriticalityBarCard'
import EntityWarningContent from '../content/EntityWarningContent'

const DomainPage: React.FC = () => {
  const { entity } = useEntity()
  const systemRelations = useRelatedEntities(entity, { type: 'hasPart', kind: 'System' }).entities ?? []

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          {EntityWarningContent}
          <Grid item md={6}>
            <EntityAboutCard variant="gridItem" />
          </Grid>
          <Grid item md={6}>
            <TelemetryDomainCriticalityBarCard systemRelations={systemRelations} />
          </Grid>
          <Grid item md={6}>
            <EntityHasSystemsCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      <EntityLayout.Route path="/docs" title="Docs">
        <EntityTechdocsContent>
          <TechDocsAddons>
            <Mermaid config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }} />
          </TechDocsAddons>
        </EntityTechdocsContent>
      </EntityLayout.Route>
      <EntityLayout.Route path="/diagram" title="Diagram">
        <EntityCatalogGraphCard
          variant="gridItem"
          direction={Direction.TOP_BOTTOM}
          title="System Diagram"
          height={700}
          relations={[
            RELATION_PART_OF,
            RELATION_HAS_PART,
            RELATION_API_CONSUMED_BY,
            RELATION_API_PROVIDED_BY,
            RELATION_CONSUMES_API,
            RELATION_PROVIDES_API,
            RELATION_DEPENDENCY_OF,
            RELATION_DEPENDS_ON
          ]}
          unidirectional={false}
        />
      </EntityLayout.Route>
    </EntityLayout>
  )
}

export default DomainPage

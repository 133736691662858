export const customTooltipStyles: Record<string, React.CSSProperties> = {
  customTooltip: {
    margin: 0,
    lineHeight: '24px',
    border: '1px solid #f5f5f5',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '10px'
  },
  intro: {
    borderTop: '1px solid #f5f5f5',
    margin: 0
  },
  description: {
    margin: 0,
    color: '#8129D9'
  }
}

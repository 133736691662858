import { Entity } from "@backstage/catalog-model"
import { getEntityRelations } from "@backstage/plugin-catalog-react"
import { analyseEntityResults, hasTelemetryData, isCriticality, ResultsAnalysis, TCriticality } from "../../util"

export type ComponentResult = ResultsAnalysis & {
  name: string
  system: string
  criticality: TCriticality | 'Unknown'
}

export const resolveComponentResults = (entities: Entity[]): ComponentResult[] => {
  return entities
    .filter((entity) => hasTelemetryData(entity) && entity.metadata.annotations?.['linktr.ee/criticality'])
    .map((entity) => {
        const analysis = analyseEntityResults(entity)
        const criticality: TCriticality | undefined =
          (entity.metadata.annotations?.['linktr.ee/criticality'] as TCriticality) ?? undefined
        return getEntityRelations(entity, 'partOf')
          .map((system) => ({
            ...analysis,
            criticality: isCriticality(criticality) ? criticality : 'Unknown' as 'Unknown',
            name: entity.metadata.name,
            system: system.name
          }))
    })
    .flat()
}

export type EntitiesByCriticality = {
  [P in TCriticality]: Entity[]
}

export const categoriseEntitiesByCriticality = (entities: Entity[]): EntitiesByCriticality => {
  const result: EntitiesByCriticality = {
    Low: [],
    Medium: [],
    High: [],
    Critical: []
  }

  entities.forEach((entity: Entity) => {
    const criticality: TCriticality | undefined =
      (entity.metadata.annotations?.['linktr.ee/criticality'] as TCriticality) ?? undefined
    if (isCriticality(criticality)) result[criticality].push(entity)
  })

  return result
}

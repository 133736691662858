import { Content, Page } from '@backstage/core-components'
import { CatalogSearchResultListItem } from '@backstage/plugin-catalog'
import { useEntityList } from '@backstage/plugin-catalog-react'
import { HomePageCompanyLogo } from '@backstage/plugin-home'
import {
  DefaultResultListItem,
  SearchBar,
  SearchContextProvider,
  SearchResult,
  useSearch
} from '@backstage/plugin-search-react'
import { Button, Grid, Link, List, Typography, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { TelemetryComponents } from 'backstage-plugin-telemetry'
import React from 'react'
import { IconContext } from 'react-icons'
import { SiBuildkite, SiDatadog, SiGithub, SiPagerduty } from 'react-icons/si'
import LogoFull from '../Root/LogoFull'
import { hasTelemetryData } from '../util/helpers'
import { Results } from '@linktr.ee/telemetry-api-client'

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    padding: '8px 8px',
    borderRadius: '50px',
    border: '2px solid',
    margin: 'auto'
  },
  center: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  button: {
    width: '100%',
    maxWidth: '60vw',
    borderRadius: '25px',
    background: 'rgba(0, 0, 0, 0)',
    border: 'none',
    borderTop: '2px solid',
    borderBottom: '2px solid',
    boxShadow: 'none'
  },
  telemetryOverviewWrapper: {
    width: '100%',
    maxWidth: '60vw',
    borderRadius: '25px',
    background: 'rgba(0, 0, 0, 0)',
    border: 'none',
    borderTop: '2px solid'
  },
  telemetryOverview: {
    maxWidth: '30vw',
    margin: 'auto'
  },
  searchResultsWrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: '60vw',
    margin: '0 auto'
  },
  searchResults: {
    position: 'absolute',
    display: 'flex',
    'z-index': '2',
    width: '100%',
    background: theme.palette.background.paper,
    top: '20px',
    boxShadow: theme.shadows[6]
  },
  searchResultsList: {
    width: '100%',
    maxHeight: '70vh',
    'overflow-y': 'scroll'
  }
}))

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0, 3, 0)
  },
  svg: {
    width: 'auto',
    height: 100
  },
  path: {
    fill: '#7df3e1'
  }
}))

const SearchResults = () => {
  const classes = useStyles()
  const search = useSearch()

  return (
    <div className={classes.searchResultsWrapper}>
      <div className={classes.searchResults}>
        {search.term !== '' && (
          <SearchResult>
            {({ results }) => (
              <List className={classes.searchResultsList}>
                {results.map(result => {
                  switch (result.type) {
                    case 'software-catalog':
                      return (
                        <CatalogSearchResultListItem
                          key={result.document.location}
                          result={result.document}
                          highlight={result.highlight}
                        />
                      )
                    default:
                      return (
                        <DefaultResultListItem
                          key={result.document.location}
                          result={result.document}
                          highlight={result.highlight}
                        />
                      )
                  }
                })}
              </List>
            )}
          </SearchResult>
        )}
      </div>
    </div>
  )
}

export const HomePage = () => {
  const classes = useStyles()
  const { container } = useLogoStyles()
  const { entities } = useEntityList()

  const callToActionAmount = entities.filter(entity => {
    const telemetryData = hasTelemetryData(entity)
      ? ((entity.metadata['linktr.ee/telemetry'] as unknown) as Results)
      : undefined

    return (
      telemetryData &&
      telemetryData.results.some(result => result.name === 'CDK Construct Library Deprecated' && !result.success)
    )
  }).length

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo className={container} logo={<LogoFull />} />
            <div className={classes.center}>
              <IconContext.Provider value={{ size: '2em' }}>
                <div>
                  <a href="https://github.com/blstrco" aria-label="GitHub">
                    <SiGithub style={{ marginRight: '10px' }} />
                  </a>
                  <a href="https://www.buildkite.com/linktree" aria-label="Buildkite">
                    <SiBuildkite style={{ marginRight: '10px' }} />
                  </a>
                  <a href="https://app.datadoghq.com" aria-label="Datadog">
                    <SiDatadog style={{ marginRight: '10px' }} />
                  </a>
                  <a href="https://linktree.pagerduty.com" aria-label="Pagerduty">
                    <SiPagerduty style={{ marginRight: '10px' }} />
                  </a>
                </div>
              </IconContext.Provider>
              <br />
              <p>Welcome to Backstage!</p>
              <br />
            </div>
            <Grid container item xs={12} alignItems="center" direction="row">
              <SearchBar
                classes={{ root: classes.searchBar }}
                placeholder="Search for software entities and their documentation"
                debounceTime={100}
              />
              <SearchResults />
            </Grid>
            <Grid container item xs={12} alignItems="center" direction="row">
              <div className={classes.center}>
                <h2>Did you know? 👇</h2>
                <Button className={classes.button} variant="outlined" fullWidth href="/telemetry">
                  <span className={classes.center}>
                    {entities ? (
                      <>
                        <h3>
                          🎉 <b>@linktr.ee/cdk-construct-library-v2</b> has been released! 🎉
                        </h3>
                        <p style={{ fontSize: 15, margin: 0 }}>
                          <b style={{ color: callToActionAmount === 0 ? 'green' : 'red' }}>{callToActionAmount}</b> of
                          Linktree's services are using the deprecated <b>@linktr.ee/cdk-construct-library</b> package.{' '}
                          {callToActionAmount === 0 ? `🎉` : `😱`}
                        </p>
                        <Typography color="primary" style={{ fontSize: 16, lineHeight: 4 }} variant="body1">
                          ✨{' '}
                          <b>
                            Upgrading takes just one click!{' '}
                            <Link
                              color="primary"
                              href="https://backstage.platform.linktr.ee/docs/default/component/cdk-construct-library-v2/v2-upgrade/"
                              underline="always"
                            >
                              See the upgrade guide
                            </Link>{' '}
                            for more information.
                          </b>{' '}
                          ✨
                        </Typography>
                      </>
                    ) : (
                      <>
                        <br />
                        <Skeleton variant="rect" animation="wave" width={200} />
                        <br />
                        <Skeleton variant="rect" animation="wave" width={300} />
                        <br />
                        <Skeleton variant="rect" animation="wave" width={200} />
                        <br />
                      </>
                    )}
                  </span>
                </Button>
              </div>
            </Grid>
            <Grid container item xs={12} alignItems="center" direction="row">
              <div className={classes.center}>
                <h2>Telemetry 🚦</h2>
                <div className={classes.telemetryOverviewWrapper}>
                  {entities ? (
                    <>
                      <h3>Here's a chart showing an overview of Telemetry results, grouped by category</h3>
                      <div className={classes.telemetryOverview}>
                        <TelemetryComponents.ComponentsByCategoryRadialBarChart
                          entities={entities}
                          toolTipVariant="percent"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <br />
                      <Skeleton variant="rect" animation="wave" width={200} style={{ margin: 'auto' }} />
                      <br />
                      <Skeleton variant="rect" animation="wave" width={300} style={{ margin: 'auto' }} />
                      <br />
                      <Skeleton variant="circle" animation="wave" width={200} height={200} style={{ margin: 'auto' }} />
                      <br />
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  )
}

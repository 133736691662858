import { Result } from '@linktr.ee/telemetry-api-client'
import moment from 'moment'
import React from 'react'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { customTooltipStyles } from '../../../util/styles'
import { TelemetryCategoryResult } from '../cards/TelemetryCategoryResultsCard'

const formatPercent = (decimal: number) => `${decimal}%`
const formatDate = (value: number) => moment(value).fromNow()

const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  if (active && payload) {
    const numberOfPassingChecks = payload[0].payload.results.filter(({ success }: Result) => success).length
    const numberOfChecks = Object.values(payload[0].payload.results).length
    const date = moment(payload[0].payload.updatedAt).format('DD/MM/YYYY HH:mm:ss (Z)')

    return (
      <div style={customTooltipStyles.customTooltip}>
        <b>Passed</b>: {`${numberOfPassingChecks}/${numberOfChecks}`}
        <br />
        <b>Date</b>: {date}
      </div>
    )
  }

  return null
}

export type TelemetryCategoryPercentAreaChartData = {
  /**
   * The name of the category
   */
  name: string
  /**
   * The percentage value for the category to display on the chart
   */
  value: number
  /**
   * The hexadecimal colour for the bar
   */
  fill: string
}

export type TelemetryCategoryPercentAreaChartProps = {
  data: TelemetryCategoryResult[]
  colour?: string
  /**
   * The data to be displayed on the chart
   */
  chartStyle?: {
    containerHeight?: number
    horizontalAlignment?: string
    innerRadius?: string
    outerRadius?: string
  }
  onMouseMove?: (nextState: CategoricalChartState, event: any) => void
}

export const TelemetryCategoryPercentAreaChart: React.FC<TelemetryCategoryPercentAreaChartProps> = ({
  chartStyle,
  data,
  colour,
  onMouseMove
}) => {
  return (
    <ResponsiveContainer width="100%" height={chartStyle?.containerHeight ?? 350}>
      <AreaChart
        data={data}
        stackOffset="expand"
        margin={{
          top: 10,
          right: 20,
          left: 0,
          bottom: 10,
        }}
        onMouseMove={onMouseMove}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="updatedAt" tickFormatter={formatDate} />
        <YAxis tickFormatter={formatPercent} domain={[0, 100]} />
        <Tooltip content={CustomTooltip} />
        <Area type="linear" dataKey="value" fill={colour} />
      </AreaChart>
    </ResponsiveContainer>
  )
}

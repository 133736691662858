import { ANNOTATION_EDIT_URL, ANNOTATION_LOCATION, stringifyEntityRef } from '@backstage/catalog-model'
import { HeaderIconLinkRow, IconLinkVerticalProps, InfoCardVariants, Link } from '@backstage/core-components'
import {
  alertApiRef,
  configApiRef,
  ErrorApiError,
  errorApiRef,
  identityApiRef,
  useApi
} from '@backstage/core-plugin-api'
import { ScmIntegrationIcon, scmIntegrationsApiRef } from '@backstage/integration-react'
import { AboutContent } from '@backstage/plugin-catalog'
import { catalogApiRef, getEntitySourceLocation, useEntity } from '@backstage/plugin-catalog-react'
import { Card, CardContent, CardHeader, Divider, Grid, IconButton, makeStyles } from '@material-ui/core'
import CachedIcon from '@material-ui/icons/Cached'
import EditIcon from '@material-ui/icons/Edit'
import NetworkCheck from '@material-ui/icons/NetworkCheck'
import React, { useCallback } from 'react'
import { setTokenCookie } from '../../cookieAuth'

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px'
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  gridItemCardContent: {
    flex: 1
  },
  fullHeightCardContent: {
    flex: 1
  }
})

/**
 * Props for {@link EntityAboutCard}.
 *
 * @public
 */
export interface AboutCardProps {
  childElement?: JSX.Element
  variant?: InfoCardVariants
}

/**
 * A vendored version of the standard AboutCard, with the ability to inject JSX elements at the bottom
 */
export const AboutCardWithChildren: React.FC<AboutCardProps> = ({ childElement, variant }) => {
  const classes = useStyles()
  const { entity } = useEntity()
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef)
  const catalogApi = useApi(catalogApiRef)
  const alertApi = useApi(alertApiRef)
  const errorApi = useApi(errorApiRef)
  const configApi = useApi(configApiRef)
  const identityApi = useApi(identityApiRef)

  const entitySourceLocation = getEntitySourceLocation(entity, scmIntegrationsApi)
  const entityMetadataEditUrl = entity.metadata.annotations?.[ANNOTATION_EDIT_URL]

  const viewInSource: IconLinkVerticalProps = {
    label: 'View Source',
    disabled: !entitySourceLocation,
    icon: <ScmIntegrationIcon type={entitySourceLocation?.integrationType} />,
    href: entitySourceLocation?.locationTargetUrl
  }

  let cardClass = ''
  if (variant === 'gridItem') {
    cardClass = classes.gridItemCard
  } else if (variant === 'fullHeight') {
    cardClass = classes.fullHeightCard
  }

  let cardContentClass = ''
  if (variant === 'gridItem') {
    cardContentClass = classes.gridItemCardContent
  } else if (variant === 'fullHeight') {
    cardContentClass = classes.fullHeightCardContent
  }

  const entityLocation = entity.metadata.annotations?.[ANNOTATION_LOCATION]
  // Limiting the ability to manually refresh to the less expensive locations
  const allowRefresh = entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:')
  const refreshEntity = useCallback(async () => {
    try {
      await catalogApi.refreshEntity(stringifyEntityRef(entity))
      alertApi.post({ message: 'Refresh scheduled', severity: 'info' })
    } catch (e) {
      errorApi.post(e as ErrorApiError)
    }
  }, [catalogApi, alertApi, errorApi, entity])

  const refreshTelemetry = async () => {
    const backendUrl = configApi.getString('backend.baseUrl')

    try {
      alertApi.post({ message: 'Running Telemetry', severity: 'info' })

      const owner =
        entity.metadata.annotations?.['backstage.io/source-location'].match(
          new RegExp(/(?<=github.com\/)(\w+)(?=\/)/)
        )?.[0] ?? 'blstrco'
      const repo =
        entity.metadata.annotations?.['backstage.io/source-location'].match(
          new RegExp(/(?<=blstrco\/)(.*)(?=\/t)/)
        )?.[0] ?? entity.metadata.name

      await setTokenCookie(`${backendUrl}/api/refresh-telemetry/refresh`, identityApi, {
        body: JSON.stringify({
          owner,
          repo
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      })
    } catch (e) {
      errorApi.post(e as ErrorApiError)
    }
  }

  return (
    <Card className={cardClass}>
      <CardHeader
        title="About"
        action={
          <>
            {allowRefresh && (
              <IconButton aria-label="Refresh" title="Schedule entity refresh" onClick={refreshEntity}>
                <CachedIcon />
              </IconButton>
            )}
            <IconButton aria-label="Run Telemetry" title="Run Telemetry" onClick={refreshTelemetry}>
              <NetworkCheck />
            </IconButton>
            <IconButton
              component={Link}
              aria-label="Edit"
              disabled={!entityMetadataEditUrl}
              title="Edit Metadata"
              to={entityMetadataEditUrl ?? '#'}
            >
              <EditIcon />
            </IconButton>
          </>
        }
        subheader={<HeaderIconLinkRow links={[viewInSource]} />}
      />
      <Divider />
      <CardContent className={cardContentClass}>
        <Grid container direction="row">
          <Grid item md={12}>
            <AboutContent entity={entity} />
            {childElement && <Divider style={{ marginTop: '20px' }} />}
          </Grid>
          <Grid item md={12}>
            {childElement}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

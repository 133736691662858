import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Route } from 'react-router'
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs'
import { CatalogEntityPage, CatalogIndexPage, catalogPlugin } from '@backstage/plugin-catalog'
import { CatalogImportPage, catalogImportPlugin } from '@backstage/plugin-catalog-import'
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder'
import { orgPlugin } from '@backstage/plugin-org'
import { SearchPage } from '@backstage/plugin-search'
import { TechRadarPage } from '@backstage/plugin-tech-radar'
import { UserSettingsPage } from '@backstage/plugin-user-settings'
import { apis } from './apis'
import { entityPage } from './components/catalog/EntityPage'
import { Root } from './components/Root'
import { linktreeTheme } from './themes/linktreeTheme'
import { darkTheme, lightTheme } from '@backstage/theme'
import { TechDocsIndexPage, TechDocsReaderPage } from '@backstage/plugin-techdocs'
import { TechDocsAddons } from '@backstage/plugin-techdocs-react'
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid'
import LinktreeLogo from './components/assets/LinktreeLogo'
import { TextSize } from '@backstage/plugin-techdocs-module-addons-contrib'

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInProviderConfig,
  SignInPage,
  IdentityProviders
} from '@backstage/core-components'
import { createApp } from '@backstage/app-defaults'
import { FlatRoutes } from '@backstage/core-app-api'
import { discoveryApiRef, githubAuthApiRef, oktaAuthApiRef, IdentityApi, useApi } from '@backstage/core-plugin-api'
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph'

import { PermissionedRoute } from '@backstage/plugin-permission-react'
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha'
import { HomepageCompositionRoot } from '@backstage/plugin-home'
import { HomePage } from './components/home/HomePage'
import { setTokenCookie } from './cookieAuth'

// import { createTheme, lightTheme } from '@backstage/theme'
// import CssBaseline from '@material-ui/core/CssBaseline'
// import LightIcon from '@material-ui/icons/WbSunny'
// import { ThemeProvider } from '@material-ui/core/styles'
import { TelemetryPage } from 'backstage-plugin-telemetry'
import { EntityKindPicker, EntityListProvider } from '@backstage/plugin-catalog-react'

const githubProvider: SignInProviderConfig = {
  id: 'github-auth-provider',
  title: 'GitHub',
  message: 'Sign in using GitHub',
  apiRef: githubAuthApiRef
}

const oktaProvider: SignInProviderConfig = {
  id: 'okta-auth-provider',
  title: 'Okta',
  message: 'Sign in using Okta',
  apiRef: oktaAuthApiRef
}

const providers: IdentityProviders = [githubProvider, oktaProvider]

const app = createApp({
  apis,
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef)

      return (
        <SignInPage
          {...props}
          auto
          providers={providers}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            setTokenCookie(await discoveryApi.getBaseUrl('cookie'), identityApi)

            props.onSignInSuccess(identityApi)
          }}
        />
      )
    }
  },
  themes: [
    {
      id: 'light',
      title: 'Light',
      variant: 'light',
      Provider: ({ children }) => (
        <ThemeProvider theme={lightTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      )
    },
    {
      id: 'dark',
      title: 'Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <ThemeProvider theme={darkTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      )
    },
    {
      id: 'linktree',
      title: 'Linktree',
      variant: 'light',
      icon: <LinktreeLogo expanded={false} fill="#43E660" />,
      Provider: ({ children }) => (
        <ThemeProvider theme={linktreeTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      )
    }
  ],
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root
    })
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage
    })
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage
    })
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex
    })
  }
  /**
   * Un-comment to Comic Sans the Backstage UI (experimental)
   */
  // themes: [
  //   {
  //     id: 'its-a-prank-bro',
  //     title: 'Super Important Theme',
  //     variant: 'dark',
  //     icon: <LightIcon />,
  //     Provider: ({ children }) => (
  //       <ThemeProvider theme={itsAPrankBro}>
  //         <CssBaseline>{children}</CssBaseline>
  //       </ThemeProvider>
  //     )
  //   }
  // ]
})

const AppProvider = app.getProvider()
const AppRouter = app.getRouter()

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <EntityListProvider>
        <EntityKindPicker initialFilter="Component" allowedKinds={['Component']} hidden />
        <HomePage />
      </EntityListProvider>
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route path="/catalog/:namespace/:kind/:name" element={<CatalogEntityPage />}>
      {entityPage}
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          groups={[
            {
              title: 'Recommended',
              filter: entity => entity?.metadata?.tags?.includes('recommended') ?? false
            }
          ]}
        />
      }
    />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/tech-radar" element={<TechRadarPage width={1500} height={800} />} />
    <PermissionedRoute
      path="/catalog-import"
      permission={catalogEntityCreatePermission}
      element={<CatalogImportPage />}
    />
    <Route path="/search" element={<SearchPage />} />
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route path="/docs/:namespace/:kind/:name/*" element={<TechDocsReaderPage />}>
      <TechDocsAddons>
        <TextSize />
        <Mermaid config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }} />
      </TechDocsAddons>
    </Route>
    <Route path="/telemetry" element={<TelemetryPage />} />
  </FlatRoutes>
)

const App = () => (
  <AppProvider>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </AppProvider>
)

export default App
